import {AnagTranslationsKeys} from './anag-translations-keys';
/* tslint:disable:max-line-length */
// Please keep the translation keys alphabetically ordered
export const ANAG_ES: AnagTranslationsKeys = {
  _ANAG_: {
    _BTN_: {
      _ADD_: 'Añadir',
      _ADD_ADDRESS_: 'Añadir dirección',
      _BACK_: 'Atrás',
      _CALCULATE_: 'Calcular',
      _CANCEL_: 'Cancelar',
      _CLOSE_: 'Cerrar',
      _CONFIRM_: 'Confirmar',
      _DETAIL_: 'Información',
      _EDIT_: 'Editar',
      _EMPTY_: 'Limpiar',
      _FIND_: 'Buscar',
      _NEW_: 'Nuevo',
      _NEW_PAYMENT_: 'Nuevo pago',
      _NORMALIZE_: 'NORMALIZAR',
      _SAVE_: 'Guardar',
      _DELETE_: 'Suprimir',
      _DELETE_DEFINITIVELY_: 'Suprimir definitivamente',
      _HISTORY_: 'Historial',
      _LIMITATION_MANAGEMENT_: 'Gestión de la limitación',
      _UNDO_: 'Deshacer',
      _CONTINUE_: 'Continùa'
    },
    _LABEL_: {
      _ACTIONS_: 'Acciones',
      _ACTION_TERRORISM_: 'Información sobre terrorismo',
      _ADDRESS_: 'Dirección',
      _AE_: 'IAE',
      _AGENCY_: 'Agencia',
      _ATI_ASSOCIATIONS_: 'Asociaciones UTE',
      _BIRTH_DATE_: 'Fecha de nacimiento',
      _BIRTH_SURNAME_: 'Segundo Apellido',
      _BIRTH_PLACE_: 'Lugar de nacimiento',
      _CITIZENSHIP_: 'Nacionalidad',
      _CITY_OF_BIRTH_: 'Ciudad de nacimiento',
      _CIVIL_STATE_: 'Estado civil',
      _CLIENT_NUMBER_: 'Número cliente',
      _CLIENT_UNIQUE_KEY_: 'NIF / NIE / CIF',
      _COMPANY_NAME_: 'Nombre de la compañía',
      _CONTACTS_: 'Contactos',
      _CORPORATE_FORM_: 'Forma corporativa',
      _CORPORATE_SECTOR_: 'Sector Comercial',
      _CORPORATION_: 'Corporación',
      _COWORKER_: 'Código fiscal de compañero de trabajo',
      _CREDIT_: 'Crédito',
      _CRITERIA_: 'Criterios',
      _DEBIT_: 'Débito',
      _DOCUMENT_: 'Documento',
      _DOCUMENT_NO_: 'Número de documento',
      _DOCUMENT_TYPE_: 'Tipo de documento',
      _EMAIL_: 'Correo electrónico',
      _EMAIL2_: 'Correo electrónico 2',
      _EMAIL3_: 'Correo electrónico 3',
      _EXPIRATION_DATE_: 'Fecha de vencimiento',
      _FAX_NO_: 'Número de fax',
      _FEMALE_: 'Mujer',
      _FILL_ID_DATA_: 'Cumplimente datos de identificación del cliente',
      _FISCAL_CODE_: 'Código fiscal',
      _FISCAL_CODE_VAT_NUMBER_: 'Código fiscal / NIF',
      _GENDER_: 'Género',
      _GENDER_IDENTITY_: 'Identidad de género',
      _IDENTIFICATION_DATA_: 'Datos de identificación',
      _INT_PREFIX_: 'Int. Prefijo',
      _LANDLINE_NO_: 'Número fijo',
      _PHONE_NO_: 'Número Teléfono',
      _LEADER_: 'Líder',
      _LINK_TYPE_: 'Tipo de relación',
      _LINKED_PARTIES_: 'Relaciones',
      _MALE_: 'Hombre',
      _MAIN_CONTACT_: 'Contacto principal',
      _MANAGEMENT_NODE_: 'Mediador',
      _MOBILE_NO_: 'Número móvil',
      _MOBILE_NO_2_: 'Número móvil 2',
      _MUNICIPALITY_: 'Municipio',
      _NAME_: 'Nombre',
      _NATION_: 'Nacionalidad',
      _NODES_: 'Nodos',
      _NO_ADDRESS_MSG_: 'Sin dirección',
      _NUMBER_: 'Número',
      _OPEN_DATE_: 'Fecha abierta',
      _PARTICIPANTS_: 'Participantes',
      _PARTIES_: 'Clientes',
      _PARTY_: 'Cliente',
      _PARTY_DATA_: 'Datos de la parte',
      _PARTY_MANAGEMENT_: 'Gestión de clientes',
      _PARTY_MERGER_: 'Fusión de partes',
      _PARTY_NUMBER_: 'Número de parte',
      _PARTY_TYPE_: 'Tipo de parte',
      _PAYMENT_: 'Datos bancarios',
      _PERSON_TYPE_: 'Tipo de Persona',
      _PHYSICAL_: 'Física',
      _POTICALLY_EXP: 'Persona de responsabilidad pública o familiar​',
      _PREFIX_: 'Prefijo',
      _PRIVACY_: 'Privacidad',
      _PRIVACY_CONSENSUS_DATE_: 'Fecha de aceptación de la privacidad',
      _CHANGED_ON_: 'Modificado el',
      _PRODUCTION_NODES_: 'Nodos de producción',
      _PROFESSION_: 'Profesión',
      _PROFESSION_DETAIL_: 'Detalle de profesión',
      _REGISTERED_OFFICE_: 'Oficina registrada',
      _RELEASE_AUTHORITY_: 'Autoridad de emisión',
      _RELEASE_DATE_: 'Fecha de expedición',
      _RELEASE_LOCATION_: 'Lugar de expedición',
      _REQUIRED_FIELD_: 'Requerido',
      _RESIDENCE_: 'Domicilio fiscal',
      _ANTI_TERRORISM_: 'Lucha contra el terrorismo',
      _RESIDENCE_REGISTERED_OFFICE_: 'Residencia / Oficina registrada',
      _SAE_: 'CNAE',
      _SALE_POINT_: 'Mediador',
      _SAVED_OK_: 'guardado correctamente',
      _SELECT_PARTY_: 'Seleccionar cliente',
      _SEX_: 'Sexo',
      _STATUS_: 'Estado',
      _STATUS_NOT_PROCESSED_: 'No procesado',
      _STATUS_PROCESSED_: 'Procesado',
      _SUBJECT_: 'Cliente',
      _SUBJECT_DATA_: 'Datos del cliente',
      _SUBJECT_TYPE_: 'Tipo de persona',
      _SUBJECT_UNIQUE_KEY_: 'Identificación del sujeto',
      _SUBJNO_: 'N.º de parte',
      _SUBSYSTEM_: 'Subsistema',
      _SUBSYSTEM_PERSON_ID_: 'ID de persona del subsistema',
      _SURNAME_: 'Apellido',
      _SURNAMEORCOMPANY_: 'Apellido / Nombre de la compañía',
      _VOID_: ' ',
      _VAT_NUMBER_: 'NIF',
      _DATE_MODIFIED_COUNTER_TERRORISM_INDICATOR_: 'Fecha editada de lucha contra el terrorismo',
      _COUNTER_TERRORISM_INDICATOR_: 'Estado de lucha contra el terrorismo',
      _DOMICILES_: 'Domicilios',
      _DOMICILE_: 'Domicilio',
      _PRINCIPAL_ADDRESS_: 'Dirección principal',
      _AVAILABILITY_: 'Disponibilidad',
      _TYPE_: 'Tipo',
      _AVAILABILITY_START_DATE_: 'Fecha de inicio de la disponibilidad',
      _AVAILABILITY_END_DATE_: 'Fecha de final de la disponibilidad',
      _AVAILABLE_FROM_: 'Formulario disponible',
      _AVAILABLE_UNTIL_: 'Unidad disponible',
      _INTERNATIONAL_DIALLING_CODE_: 'Código de marcado internacional',
      _LOCAL_AREA_DIAL_CODE_: 'Código de marcado de área local',
      _YES_: 'Sí',
      _NO_: 'No',
      _COUNTERTERRORISM_STATUS_ : 'Estado de lucha contra el terrorismo',
      _COUNTERTERRORISM_INDICATOR_CHANGE_DATE_ : 'Fecha editada de lucha contra el terrorismo',
      _ORIGIN_: 'Origen',
      _LANGUAGE_: 'Lengua',
      _HONOR_TITLE_ : 'Título de Honor',
      _SONS_: 'Número de niños',
      _ROBINSON_LIST_: 'Lista Robinson',
      _CITIZENSHIP_PERMIT_: 'Permiso de residencia',
      _CITIZENSHIP_EXPIRE_: 'Residencia válida hasta',
      _REG_COUNTRY_: 'País de registro',
      _EXTERNALS_IDENTIFIERS_: 'Identificadores externos',
      _IDENTIFIERS_SUBJECT_SUBSYSTEM_ : 'Identificadores Asunto Subsistema',
      _TAX_CLASSIFICATION_: 'Datos FATCA / CRS',
      _TIN_:'TIN / EIN / SSN USA​',
      _RISK_CLASSIFICATION_ : 'Clasificación de Riesgo',
      _REASON_CLASSIFICATION_ : 'Clasificación de Razón',
      _BASIS_CLASSIFICATION_ : 'Clasificación de Básico',
      _CLASSIFICATION_DATE_ : 'Fecha de Clasificación',
      _COUNTRY_START_DATE_ : 'Fecha de Inicio del País',
      _COUNTRY_END_DATE_ : 'Fecha de Finalización del País',
      _NO_TAX_CLASSIFICATIONS_ : 'Sin datos FATCA / CRS',
      _COUNTRY_ : 'País' ,
      _NOMINATIVE_ : 'Nominativo',
      _HISTORY_CONSULTATION_: 'consulta de historia',
      _DO_NOT_REPLY_: 'No establecido',
      _DRIVING_LICENSE_TYPE_: 'Tipo de licencia de conducir',
      _DRIVER_QUALIFICATION_CARD_: 'Tarjeta de calificación del conductor',
      _ADD_DRIVING_LICENSE_TYPE_: 'Agregar tipo de licencia de conducir',
      _PRIVACY_INFO_MSG_: 'El campo consulta los archivos oficiales de exclusión publicitaria conocidos como Lista Robinson (RL) y nos indica si el cliente está registrado en la RL o no.\n' +
        '\n' +
        'Si la Lista Robinson está configurada en Sí, no podremos enviar comunicaciones comerciales.\n' +
        '\n' +
        'Si la Lista Robinson está configurada en No, las comunicaciones comerciales solo se enviarán cuando el campo Excluir publicidad esté configurado en No.\n' +
        '\n' +
        'Si el cliente no ha sido consultado en la RL, el campo aparece sin valor.',
      _LEI_CODE_: 'Codigo LEI',
      _RATING_: 'Rating cliente',
      _RATING_DATE_: 'Fecha de calificación',
      _RATING_PERIOD_: 'Periodo del rating',
      _RATING_USER_: 'Usuario',
      _RATING_ORIGIN_: 'Origen',
      _RATING_HISTORY_: 'Historial de calificaciones',
      _COWORKERS_: 'Empleados',
      _COMPANY_: 'Empresa'
    },
    _MSG_: {
      _ASK_DELETION_CONFIRM_: '¿Confirma la eliminación del sujeto?',
      _DELETION_CONFIRMED_: 'El sujeto se ha eliminado correctamente',
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Los campos marcados en rojo son obligatorios o contienen valores incorrectos',
      _ERROR_DATE_: 'Sujeto menor de 16 años. El consentimiento al tratamiento de datos se entiende prestado por los padres/tutores legales.',
      _ERROR_DATE_SP_: 'Consentimiento para el tratamiento de datos personales de menores de 14 años',
      _INVALID_DATE_VALUE: 'Fecha no válida',
      _INVALID_FIELD_: 'Campo no válido',
      _LENGTHERR_: 'Longitud de campo no permitida',
      _LINK_ERROR_: 'Parte ya introducida',
      _LINK_ERROR_SAME_PARTY_: 'Imposible vincular una parte a sí misma',
      _NO_ELEMENT_TO_SHOW_: 'No hay elementos para mostrar',
      _NO_LINKED_PARTIES_: 'Sin vínculos',
      _NO_RESULTS_: 'Sin resultados',
      _NORMALIZED_ADDRESS_NOT_FOUND_: 'No se ha encontrado la dirección normalizada',
      _PATTERNERR_: 'El campo contiene caracteres incorrectos',
      _REFINE_SEARCH_: 'La extracción es parcial. Afine su búsqueda',
      _SEARCH_FORM_MANDATORY_FIELDS_ERROR_: 'Complete al menos uno de: Apellido / Nombre de la compañía, código fiscal o NIF, número de cliente, número de parte, número de teléfono, código postal válido o ID personal del subsistema',
      _WRONG_INPUT_DATA_: 'El flujo de gestión de partes se invoca con valores de entrada no admitidos.',
      _INVALID_END_DATE_DOMICILE_: 'Introducir una fecha de fin disponibilidad posterior a la fecha de inicio disponibilidad',
      _INVALID_START_DATE_DOMICILE_: 'Introducir una fecha de inicio disponibilidad anterior a la fecha de fin disponibilidad',
      _INHERITANCE_: 'Ya existe un registro con la misma clave única en la red comercial. Presione confirmar si desea utilizar los datos del sujeto ya registrado en el registro, de lo contrario no será posible continuar con esta mejora de la clave única.',
      _TAX_CLASSIFICATION_SAME_COUNTRY_TYPE_ERROR_ : 'Ya existe una clasificación fiscal válida para el país, tipo y período seleccionado',
      _INFO_HOMONYMIES_: 'Atención! Se han encontrado partes que posiblemente sean homonímias.\n' +
        'Seleccione un partido de la lista para acceder a editar sus datos o el botón CONTINÙA para continuar insertando el nuevo partido.',
      _INFO_NATURAL_PERSON_ALLOWED_: 'Persona natural solo permitido',
      _INFO_LEGAL_ENTITY_ALLOWED_: 'Solo se permite entidad legal',
      _EXPIRATION_DATE_BEFORE_RELEASE_: 'La fecha de lanzamiento debe ser anterior a la fecha de vencimiento',
      _IBAN_INVALID_ : 'Formato IBAN incorrecto',
      _EXPIRATION_DATE_AFTER_RELEASE_: 'Introducir una fecha de vencimiento posterior a la fecha de emisión del documento',
      _ASK_DELETION_TAX_CLASSIFICATION_CONFIRM_: '¿Confirmar la eliminación de la clasificación fiscal actual?',
      _ASK_DELETION_DOCUMENT_CONFIRM_: '¿Confirma la eliminación del documento actual?',
      _DOC_ALREADY_EXIST: 'El número de documento ya está presente',
      _INSERT_DOC_TYPE: 'Introduzca el tipo de documento antes de continuar.',
      _INVALID_FORMAT_: 'Formato no válido.',
      _NORMALIZE_ERROR_MSG: 'Es obligatorio normalizar la dirección.'
    }
  },
  _ANAG_TITLE_EDIT_PARTY_: 'Editar cliente​',
  _ANAG_TITLE_PARTY_DETAIL_: 'Detalle del cliente',
  _ANAG_TITLE_PARTY_MANAGEMENT_: 'Gestión de clientes',
  _ANAG_TITLE_PARTY_ZOOM_: 'Zoom de la parte',
  _ANAG_TITLE_SEARCH_RESULTS_: 'Listado de clientes​',
  _ANAG_TITLE_SEARCH_RESULTS_HOMONYMY: ''
};

import {Inject, Injectable} from '@angular/core';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {combineLatest, Observable, of} from 'rxjs';
import {AnagStatePartyEditor} from './anag-state-manager-party-editor';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {AnagResourceService} from '../../anag-resources/anag-resource.service';
import {ECONOMIC_ACTIVITY_GROUP_ENUM} from '../../anag-constants/enums-constants';
import {EnumsApiDetail} from '../../anag-model/enum-api/enums-api';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagApiParty, AnagPartyKey, AnagPartyKeyService} from '../../anag-model/anag-domain/anag-api-party';
import {
  AnagApiPartyKeyConfigRequest,
  AnagPartyUniqueKeyResponse
} from '../../anag-model/anag-api/anag-api-party-key-config-request';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {AnagApiCreateSubject, AnagApiSaveSubjectResponse} from '../../anag-model/anag-api/anag-api-create-subject';
import {AnagDeleteResponse} from '../../anag-model/anag-domain/anag-api-ptfall-outcome';
import {AnagDialogComponent, ModalDialogData} from '../../anag-components/modal/anag-dialog/anag-dialog.component';
import {ModalService} from '@rgi/rx/ui';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AvailableLinksResponse} from '../../anag-model/anag-api/available-links-response';
import {AnagIdentificationEntity} from '../../anag-model/anag-domain/anag-identification-entity';
import {CORE_PARTY_CONFIG, PACONF_VALUES, PAYMENT_CONFIG_ANAG} from '../../anag-constants/anag-constants';
import {
  AnagApiConfiguration,
  AnagApiPartyTypeConfig,
  AnagConfigurationFilter
} from '../../anag-model/anag-api/anag-api-configuration-filter';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AnagDynamicObj} from '../../anag-model/anag-domain/anag-dynamic-obj';
import {ROUTES_DETAIL} from '../../anag-constants/routes-constants';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {AnagUtilsService} from "../../anag-resources/anag-utils.service";
import { AnagExtensionSubject } from '../../anag-model/anag-domain/anag-extension-subject';


@Injectable({
  providedIn: 'root'
})
export class AnagStatelessOpPartyEditorService {

  constructor(
    protected routingService: RoutingService,
    protected anagResourceService: AnagResourceService,
    protected anagStorage: AnagStorageService,
    protected statelessService: AnagStatelessService,
    protected modalService: ModalService,
    protected integrationService: AnagIntegrationService,
    protected i18nService: RgiRxTranslationService,
    protected anagIntegration: AnagIntegrationService,
    protected anagUtilsService: AnagUtilsService,
    @Inject('authService') private authService,
  ) {
  }

  initPartyEditor$(initialState$: Observable<AnagStatePartyEditor>): Observable<AnagStatePartyEditor> {
    return initialState$.pipe(
      mergeMap((st: AnagStatePartyEditor) => {
        st.errors = [];
        if (!!st.configuration) {
          const conf = new AnagApiConfiguration();
          conf.output = st.configuration;
          return combineLatest([of(st),
            this.anagStorage.getPrivacyEnums(),
            of(conf),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.partyHomonymy).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.anagCheckCFDuplicato).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.anagGruppoCompagnie).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getIntPrefixes(null).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.retrieveMeansOfPayment(PAYMENT_CONFIG_ANAG.PARTY)]);
        } else {
          const configFilter = new AnagConfigurationFilter();
          configFilter.partyType = st.party.subjectType && st.party.subjectType.codice ? parseInt(st.party.subjectType.codice) : PACONF_VALUES.FILTER_DEFAULT;
          configFilter.country = st.party.partyKey && st.party.partyKey.length > 0 ? st.party.partyKey[0].country : '';
          configFilter.legalForm = PACONF_VALUES.FILTER_DEFAULT;
          configFilter.partyRole = st.flowData && st.flowData.partyRole ? st.flowData.partyRole : PACONF_VALUES.FILTER_DEFAULT;
          var _operator = this.authService.getOperator();
          if(_operator.liquidationCentre) {
            configFilter.partyRole =888;
          }
         
          if (configFilter.extensionSubject == null) {
            configFilter.extensionSubject = new AnagExtensionSubject();
          }
          if (configFilter.extensionSubject.properties == null) {
            configFilter.extensionSubject.properties = [];
          }
          configFilter.extensionSubject.properties.push({chiave: 'idParty', valore: st.party.objectId});
          return combineLatest([of(st),
            this.anagStorage.getPrivacyEnums(),
            this.statelessService.retrievePartyConf(this.statelessService.getFlowDataUpdatedConfFilter(configFilter, st.flowData)),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.partyHomonymy).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.anagCheckCFDuplicato).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.anagGruppoCompagnie).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getIntPrefixes(null).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.retrieveMeansOfPayment(PAYMENT_CONFIG_ANAG.PARTY)]);
        }
      }),
      map(([updatedState, privacyOpts, conf, systemPropPartyHomonymy, systemPropPartyCFDuplicate, systemPropPartyGroupCompanies, internationalPrefixes, meansOfPayment]) => {
        updatedState.privacyQuestions = privacyOpts;
        if (typeof conf === 'string') {
          if (updatedState.errors.length === 0) {
            updatedState.errors.push(conf);
          }
          updatedState.blockedFlow = true;
        } else if (conf.error && conf.error.descrizioneErroreOccorso) {
          updatedState.errors.push(conf.error.descrizioneErroreOccorso);
          updatedState.blockedFlow = true;
        } else if (!conf.output.partyConfig) {
          this.i18nService.translate('_ANAG_._MSG_._WRONG_INPUT_DATA_').subscribe(tr => {
            updatedState.errors.push(tr);
            updatedState.blockedFlow = true;
          }).unsubscribe();
        } else {
          updatedState.configuration = conf.output;
          updatedState.editResidenceEnable = !this.isModifyResidenceDisable(conf.output);
        }
        if (systemPropPartyHomonymy && systemPropPartyHomonymy.value && systemPropPartyHomonymy.key) {
          updatedState.isUmaPartyHomonymyEnable = systemPropPartyHomonymy.value === 'Enable';
        }
        if (systemPropPartyCFDuplicate && systemPropPartyCFDuplicate.value) {
          updatedState.isUmaPartyCFDuplicate = systemPropPartyCFDuplicate.value === 'true';
        }
        if (systemPropPartyGroupCompanies && systemPropPartyGroupCompanies.value) {
          updatedState.isUmaPartyGroupCompanies = systemPropPartyGroupCompanies.value === 'true';
        }
        updatedState.meansOfPayment = meansOfPayment;
        if (internationalPrefixes) {
          updatedState.internationalPrefixes = internationalPrefixes;
        }
        updatedState.party.dynamicObjects = this.getConfUpdatedDynObjs(updatedState.party.dynamicObjects,
          updatedState.configuration.dynamicObjects);
        return updatedState;
      })
    );
  }

  isModifyResidenceDisable(config: any) {
    const field = config.configurations.find(paConf => paConf.label === 'PasoggettoIdresidence');
    return !!field && !!field.configuration && field.configuration.codice === '5';
  }

  getCitizenshipPermitTypes(state: AnagStatePartyEditor, country: string): Observable<any> {
    return this.anagResourceService.getCitizenshipPermitTypes$(country).pipe(
      map(permitList => {
        if (permitList) {
          state.citizenshipPermitTypes = permitList;
        } else {
          state.citizenshipPermitTypes = [];
        }

        return state;
      })
    );
  }

  checkPartyKey$(filter: AnagPartyKeyService): Observable<any> {
    return this.anagResourceService.checkPartyKey$(filter).pipe(
      map(resp => {
        if (resp) {
          return resp;
        }
      })
    );
  }

  duplicateParty$(filter: AnagApiParty): Observable<any> {
    return this.anagResourceService.duplicateParty$(filter).pipe(
      map(resp => {
        if (resp) {
          return resp;
        }
      })
    );
  }

  getSubjectDetail$(idSubject, idNode, nodeCode) {
    return this.anagResourceService.getSubjectDetail$(idSubject, idNode, nodeCode).pipe(
      map(resp => {
        if (resp) {
          return resp;
        }
      })
    );
  }

  getEconomyActivityGroup$(subgroup: string, statePartyEditor: Observable<AnagStatePartyEditor>): Observable<AnagStatePartyEditor> {
    return statePartyEditor.pipe(
      mergeMap((st: AnagStatePartyEditor) => {
        return combineLatest([of(st), this.anagResourceService.getEnumByCode$(ECONOMIC_ACTIVITY_GROUP_ENUM, subgroup)]);
      }),
      map(([st, enumDetail]: [AnagStatePartyEditor, EnumsApiDetail]) => {
        this.anagStorage.addEconomyActivityGroup(subgroup, enumDetail.enumList);
        return st;
      })
    );
  }

  retrievePartyKeyConf$(st$: Observable<AnagStatePartyEditor>,
                        countryCode?: string, keyForm?: UntypedFormGroup): Observable<AnagStatePartyEditor> {
    return st$.pipe(
      mergeMap((st) => {
          return combineLatest([of(st), this.anagResourceService.getPartyTypeConfig$(this.statelessService.createPartyTypeReq(st.party))]);
        }
      ),
      mergeMap(([st, partyTypeResp]: [AnagStatePartyEditor, AnagApiPartyTypeConfig]) => {
        return combineLatest([
          of(st),
          this.anagStorage.getPartyKeysByFilter(this.createPartyKeyConfReq(st.party, countryCode, partyTypeResp))
        ]);
      }),
      map(([state, partyKeyRes]) => {
        // if (keyForm) {
        //   const existingKey = state.party.partyKey && state.party.partyKey.length ?
        //     this.statelessService.deepClone(
        //       state.party.partyKey.find(partyKeys => partyKeys.country === partyKeyRes.country)
        //     ) : undefined;
        //   this.managePartyKeyNo(1, partyKeyRes, existingKey, keyForm);
        //   this.managePartyKeyNo(2, partyKeyRes, existingKey, keyForm);
        //   this.managePartyKeyNo(3, partyKeyRes, existingKey, keyForm);
        // }
        state.partyKeyConf = partyKeyRes;
        return state;
      })
    );
  }

  private managePartyKeyNo(keyNumber: number, partyKeyRes: AnagPartyKey, partyKey: AnagPartyKey, keyForm: UntypedFormGroup) {
    if (partyKeyRes[`key${keyNumber}`]) {
      const controlKey = new UntypedFormControl('');
      if (partyKey && partyKey[`key${keyNumber}`] && partyKey.country === partyKeyRes.country) {
        controlKey.setValue(partyKey[`key${keyNumber}`].value);
      }
      if (partyKeyRes[`key${keyNumber}`].state === '1') {
        controlKey.setValidators([Validators.required]);
      }
      keyForm.setControl(`partyKey${keyNumber}`, controlKey);
    } else if (keyForm.get(`partyKey${keyNumber}`)) {
      keyForm.removeControl(`partyKey${keyNumber}`);
    }
  }

  private createPartyKeyConfReq(party: AnagApiParty, countryCode: string, partyTypeResp: AnagApiPartyTypeConfig) {
    const req = new AnagApiPartyKeyConfigRequest();
    const filter = req.partyConfigurationFilter;
    filter.country = countryCode;
    if (partyTypeResp && partyTypeResp.output.partyType && partyTypeResp.output.partyType.codice) {
      filter.partyType = partyTypeResp.output.partyType.codice;
      filter.legalForm = 999;
    }
    filter.partyRole = 999;

    return req;
  }


  saveParty(st: Observable<AnagStatePartyEditor>): Observable<AnagStatePartyEditor> {
    return st.pipe(
      mergeMap((st: AnagStatePartyEditor) => {
        this.formatCreditCardExp(st);
        return combineLatest([of(st), this.anagResourceService.saveParty(new AnagApiCreateSubject(this.anagUtilsService.convertDateToString(st.party)))]);
      }),
      map(([st, partySaveResp]: [AnagStatePartyEditor, AnagApiSaveSubjectResponse]) => {
        if (partySaveResp.subject) {
          partySaveResp.subject.dateOfBirth = partySaveResp.subject.dateOfBirth ? new Date(partySaveResp.subject.dateOfBirth) : null;
          partySaveResp.subject.privacyConsensusDate = partySaveResp.subject.privacyConsensusDate ? new Date(partySaveResp.subject.privacyConsensusDate) : null;
          st.party = partySaveResp.subject;
        }
        return st;
      })
    );

  }

   formatCreditCardExp(st: AnagStatePartyEditor) {
    if(st.party.payments) {
      st.party.payments.forEach(payment => {
        if (payment.cartaDiCreditoScadenza) {
          if (payment.cartaDiCreditoScadenza.includes('/')) {
            const creditCardExp = payment.cartaDiCreditoScadenza.split('/');
            const month = creditCardExp[0];
            const year = creditCardExp[1];
            payment.cartaDiCreditoScadenza = `${year}-${month}-01`;
          }
        }
      });
    }
  }


  calculatePartyKey$(reqParty: AnagApiParty): Observable<AnagPartyUniqueKeyResponse> {
    return this.anagResourceService.calculatePartyKey$(reqParty);
  }

  deleteParty$(state: Observable<AnagStatePartyEditor>, activeRoute: ActiveRoute, definitiveDeletion: boolean): Observable<AnagStatePartyEditor> {
    return state.pipe(
      mergeMap((st: AnagStatePartyEditor) => {
        return combineLatest([of(st), this.anagResourceService.deleteSubject(st.party.node.identification, st.party.objectId, definitiveDeletion ? '1' : '0')]);
      }),
      map(([st, resp]: [AnagStatePartyEditor, AnagDeleteResponse]) => {
        if (resp) {
          if (resp.result.outcome && resp.result.outcome.codice === '1') {
            this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(resp.result.messages[0]));
          } else {
            let msgConfirm = '';
            this.i18nService.translate('_ANAG_._MSG_._DELETION_CONFIRMED_').subscribe(translated => msgConfirm = translated).unsubscribe();
            this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(msgConfirm));
            this.integrationService.backToHome(activeRoute);
          }
        }
        return st;
      })
    );
  }

  retrieveLinkTypes$(state: Observable<AnagStatePartyEditor>, linkSubjectType: string): Observable<AnagStatePartyEditor> {
    return state.pipe(
      mergeMap((st: AnagStatePartyEditor) => {
        return combineLatest([of(st), this.anagStorage.getLinkType(st.party.personType.codice, linkSubjectType)]);
      }),
      map(([st, resp]: [AnagStatePartyEditor, Array<AnagIdentificationEntity>]) => {
        if (resp) {
          st.linkTypes.set(linkSubjectType, resp);
        }
        return st;
      })
    );
  }

  retrieveValuedLinksTypes$(state: Observable<AnagStatePartyEditor>): Observable<AnagStatePartyEditor> {
    return state.pipe(
      mergeMap((st: AnagStatePartyEditor) => {
        const idLinkSubjectTypes = [];
        st.party.links.forEach(link => {
          idLinkSubjectTypes.push(link.idSubjectLinkType);
        });
        return combineLatest([of(st), this.anagResourceService.getLinkTypes$(st.party.personType.codice, idLinkSubjectTypes)]);
      }),
      map(([st, resp]: [AnagStatePartyEditor, AvailableLinksResponse]) => {
        if (resp) {
          const typesArr = resp.availableLinkTypesList;
          st.party.links.forEach((link, index) => {
            st.linkTypes.set(link.idSubjectLinkType, typesArr[index].availableLinkType);
          });
        }
        return st;
      })
    );
  }

  getConfUpdatedDynObjs(partyDynObjs: AnagDynamicObj[], confDynObjs: AnagDynamicObj[]): AnagDynamicObj[] {
    let resPartyDynObj = partyDynObjs && partyDynObjs.length ? partyDynObjs : [];
    if (confDynObjs && confDynObjs.length) {
      if (partyDynObjs && partyDynObjs.length) {
        resPartyDynObj = partyDynObjs.filter(partyDynObj =>
          confDynObjs.some(confDynObj => confDynObj.idDynObject === partyDynObj.idDynObject)
        );
        confDynObjs.forEach(confDynObj => {
          if (!partyDynObjs.some(partyDynObj => partyDynObj.idDynObject === confDynObj.idDynObject)) {
            resPartyDynObj.push(confDynObj);
          }
        });
      } else {
        return confDynObjs;
      }
    } else {
      resPartyDynObj = [];
    }
    return resPartyDynObj;
  }

  goBackToDetailPage$(lastIdFromDamage: string, stateParty$: Observable<AnagStatePartyEditor>, activeRoute: ActiveRoute) {
    if (lastIdFromDamage) {
      this.anagIntegration.backFromSession(activeRoute);
    } else {
      return stateParty$.pipe(
        map(res => {
          this.anagIntegration.navigate(this.routingService, ROUTES_DETAIL, activeRoute.getRouteData<AnagRouteData>(), activeRoute);
          return res;
        })
      );
    }
  }

}



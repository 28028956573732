<div *ngIf="(dialogueStateSearch.getState$() | async) as searchForm">
    <form [formGroup]="searchDialogueHandlerForm" data-qa="dialogue-handler-search-form">

        <div class="container-dialogue-handler-search">
            <div class="row">
                <div class="col-md-6" *ngIf="searchForm.searchWrapperObject.dialogueFunctionResponse && searchForm.searchWrapperObject.dialogueFunctionResponse.length > 0">
                    <rgi-rx-form-field>
                    <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._FUNCTION' | translate }}</label>
                    <select rgiRxNativeSelect class="core-select form-control" formControlName="function" id="function" name="function"
                        attr.data-qa="function-select" (change)="onFunctionChange()">
                        <option ></option>
                        <option *ngFor="let function of searchForm.searchWrapperObject.dialogueFunctionResponse" [value]="function.functionCode">
                            {{ function.functionDescription }}</option>
                    </select>
                </rgi-rx-form-field>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
                <div class="col-md-6">
                <rgi-rx-form-field>
                    <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._MESSAGE' | translate }}</label>
                    <select [disabled]="!selectedFunction || selectedFunction.dialogueMsgData.length == 0" rgiRxNativeSelect class="core-select form-control" formControlName="message" id="message" name="message"
                        attr.data-qa="message-select">
                        <option></option>
                        <option *ngFor="let message of selectedFunction?.dialogueMsgData" [value]="message.dialogueMsgCode">
                            {{ message.dialogueMsgCode }} {{ message.dialogueMsgDescription }}</option>
                    </select>
                </rgi-rx-form-field>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
            </div>

            <div class="row col-md-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._DIALOGUE_DESCRIPTION' | translate }}</label>
                    <select [disabled]="objectIndex < 0" rgiRxNativeSelect class="core-select form-control" formControlName="object" id="object" name="object"
                        attr.data-qa="object-select">
                        <option></option>
                        <option *ngFor="let object of searchForm.searchWrapperObject.dialogueObjectResponse[objectIndex]?.dialogueSubject" [value]="object.code">
                            {{object.description}}</option>
                    </select>
                </rgi-rx-form-field>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>


            <div class="row col-md-12">
                <h5>{{ '_CLAIMS_._OUR_REFERENCES' | translate }}</h5>
            </div>

            <div class="row col-md-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._COMPANY_CODE' | translate }}</label>
                    <select rgiRxNativeSelect class="core-select form-control" formControlName="company" id="company" name="company"
                            attr.data-qa="object-select">
                        <option></option>
                        <option *ngFor="let company of searchForm.searchWrapperObject.dialogueCompResponse.internalCompany" [value]="company.code">
                            {{company.description}}</option>
                    </select>
                </rgi-rx-form-field>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel class="label-core" attr.data-qa="dialogue-handler-search-code-chain-label">
                            {{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</label>
                        <input rgiRxInput class="form-control" formControlName="claim" id="claim" type="text" name="claim"
                               attr.data-qa="dialogue-handler-search-claim-input">
                    </rgi-rx-form-field>
                </div>
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel class="label-core" attr.data-qa="dialogue-handler-search-code-chain-label">
                            {{ '_CLAIMS_._POTENTIAL_CLAIM_NUMBER' | translate }}</label>
                        <input rgiRxInput class="form-control" formControlName="complaint" id="complaint" type="text"
                               name="complaint" attr.data-qa="dialogue-handler-search-complaint-input">
                    </rgi-rx-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel class="label-core" attr.data-qa="dialogue-handler-search-code-chain-label">
                            {{ '_CLAIMS_._INSURED_PLATE_NUMBER' | translate }}</label>
                        <input rgiRxInput class="form-control" formControlName="carLicensePlate" id="carLicensePlate"
                               type="text" name="carLicensePlate" attr.data-qa="dialogue-handler-search-carLicensePlate-input">
                    </rgi-rx-form-field>
                </div>
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel class="label-core" attr.data-qa="dialogue-handler-search-code-chain-label">
                            {{ '_CLAIMS_._INVOLVED_PARTY_KEY' | translate }}</label>
                        <input rgiRxInput class="form-control" formControlName="taxIdCode" id="taxIdCode" type="text"
                               name="taxIdCode" attr.data-qa="dialogue-handler-search-taxIdCode-input">
                    </rgi-rx-form-field>
                </div>
            </div>

            <div class="row col-md-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel class="label-core" attr.data-qa="dialogue-handler-search-code-chain-label">
                        {{ '_CLAIMS_._INVOLVED_PARTY_IDENTIFIER'| translate }}</label>
                    <input rgiRxInput class="form-control" formControlName="damageMatch" id="damageMatch" type="text"
                           name="damageMatch" attr.data-qa="dialogue-handler-search-damageMatch-input">
                </rgi-rx-form-field>
            </div>

            <div class="row col-md-12">
                <h5>{{ '_CLAIMS_._COUNTERPARTY_REFERENCES' | translate }}</h5>
            </div>

            <div class="row col-md-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._CTP_COMPANY' | translate }}</label>
                    <select rgiRxNativeSelect class="core-select form-control" formControlName="ctpCompany" id="ctpCompany" name="ctpCompany"
                            attr.data-qa="object-select">
                        <option></option>
                        <option *ngFor="let ctpComp of filteredCompanies" [value]="ctpComp.code">
                            {{ctpComp.code}} - {{ctpComp.description}}
                        </option>
                    </select>
                </rgi-rx-form-field>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel class="label-core" attr.data-qa="dialogue-handler-search-external-key-label">{{
                                '_CLAIMS_._EXTERNAL_KEY'| translate }}</label>
                        <input rgiRxInput class="form-control" formControlName="externalKey" id="externalKey" type="text"
                               name="externalKey" attr.data-qa="dialogue-handler-search-external-key-input">
                    </rgi-rx-form-field>
                </div>
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel class="label-core" attr.data-qa="dialogue-handler-search-code-chain-label">
                            {{ '_CLAIMS_._COUNTERPARTY_PLATE_NUMBER' | translate }}</label>
                        <input rgiRxInput class="form-control" formControlName="ctpCarLicensePlate" id="ctpCarLicensePlate"
                               type="text" name="ctpCarLicensePlate"
                               attr.data-qa="dialogue-handler-search-ctpCarLicensePlate-input">
                    </rgi-rx-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel rgiRxLabel>{{ '_CLAIMS_._DATE_ENTRY_FROM' | translate }}</label>
                        <rgi-rx-date-picker [openOnClick]="false">
                            <input rgiRxInput [rgiRxDateTimeInput]="creationDateStartPicker"
                                   data-qa="dialogue-handler-search-creation-date-start-input"
                                   formControlName="creationDateStart">
                        </rgi-rx-date-picker>
                        <rgi-rx-date-time [pickerType]="'calendar'" #creationDateStartPicker></rgi-rx-date-time>
                    </rgi-rx-form-field>
                </div>
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel rgiRxLabel>{{ '_CLAIMS_._TO' | translate }}</label>
                        <rgi-rx-date-picker [openOnClick]="false">
                            <input rgiRxInput [rgiRxDateTimeInput]="creationDateEndPicker"
                                   data-qa="dialogue-handler-search-creation-date-start-input"
                                   formControlName="creationDateEnd">
                        </rgi-rx-date-picker>
                        <rgi-rx-date-time [pickerType]="'calendar'" #creationDateEndPicker></rgi-rx-date-time>
                    </rgi-rx-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel rgiRxLabel>{{ '_CLAIMS_._ELABORATION_DATE_FROM' | translate }}</label>
                        <rgi-rx-date-picker [openOnClick]="false">
                            <input rgiRxInput [rgiRxDateTimeInput]="elaborationDateStartPicker"
                                data-qa="dialogue-handler-search-creation-date-start-input"
                                formControlName="elaborationDateStart">
                        </rgi-rx-date-picker>
                        <rgi-rx-date-time [pickerType]="'calendar'" #elaborationDateStartPicker></rgi-rx-date-time>
                    </rgi-rx-form-field>
                </div>
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel rgiRxLabel>{{ '_CLAIMS_._TO' | translate }}</label>
                        <rgi-rx-date-picker [openOnClick]="false">
                            <input rgiRxInput [rgiRxDateTimeInput]="elaborationDateEndPicker"
                                data-qa="dialogue-handler-search-creation-date-start-input"
                                formControlName="elaborationDateEnd">
                        </rgi-rx-date-picker>
                        <rgi-rx-date-time [pickerType]="'calendar'" #elaborationDateEndPicker></rgi-rx-date-time>
                    </rgi-rx-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel rgiRxLabel>{{ '_CLAIMS_._EXPIRY_DATE_FROM' | translate }}</label>
                        <rgi-rx-date-picker [openOnClick]="false">
                            <input rgiRxInput [rgiRxDateTimeInput]="expiryDateStartPicker"
                                   data-qa="dialogue-handler-search-creation-date-start-input"
                                   formControlName="expiryDateStart">
                        </rgi-rx-date-picker>
                        <rgi-rx-date-time [pickerType]="'calendar'" #expiryDateStartPicker></rgi-rx-date-time>
                    </rgi-rx-form-field>
                </div>
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel rgiRxLabel>{{ '_CLAIMS_._TO' | translate }}</label>
                        <rgi-rx-date-picker [openOnClick]="false">
                            <input rgiRxInput [rgiRxDateTimeInput]="expiryDateEndPicker"
                                   data-qa="dialogue-handler-search-creation-date-start-input"
                                   formControlName="expiryDateEnd">
                        </rgi-rx-date-picker>
                        <rgi-rx-date-time [pickerType]="'calendar'" #expiryDateEndPicker></rgi-rx-date-time>
                    </rgi-rx-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel rgiRxLabel>{{ '_CLAIMS_._CLAIM_DATE_FROM' | translate }}</label>
                        <rgi-rx-date-picker [openOnClick]="false">
                            <input rgiRxInput [rgiRxDateTimeInput]="claimDateStartPicker"
                                   data-qa="dialogue-handler-search-creation-date-start-input"
                                   formControlName="claimDateStart">
                        </rgi-rx-date-picker>
                        <rgi-rx-date-time [pickerType]="'calendar'" #claimDateStartPicker></rgi-rx-date-time>
                    </rgi-rx-form-field>
                </div>
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel rgiRxLabel>{{ '_CLAIMS_._TO' | translate }}</label>
                        <rgi-rx-date-picker [openOnClick]="false">
                            <input rgiRxInput [rgiRxDateTimeInput]="claimDateEndPicker"
                                   data-qa="dialogue-handler-search-creation-date-start-input"
                                   formControlName="claimDateEnd">
                        </rgi-rx-date-picker>
                        <rgi-rx-date-time [pickerType]="'calendar'" #claimDateEndPicker></rgi-rx-date-time>
                    </rgi-rx-form-field>
                </div>
            </div>

            <div class="row" style="margin-top: 10px;">
                <div class="col-md-4">
                    <rgi-rx-form-field>
                        <label rgiRxLabel class="label-core m-r-10">{{'_CLAIMS_._ACTIVE_TASK' | translate}}</label>
                        <input rgiRxInput type="checkbox" formControlName="activeTask">
                    </rgi-rx-form-field>
                </div>
                <div class="col-md-4">
                    <rgi-rx-form-field>
                        <label rgiRxLabel class="label-core m-r-10">{{'_CLAIMS_._ERROR_MESSAGE' | translate}}</label>
                        <input rgiRxInput type="checkbox" formControlName="errorMessage">
                    </rgi-rx-form-field>
                </div>
                <div class="col-md-4">
                    <rgi-rx-form-field>
                        <label rgiRxLabel class="label-core m-r-10">{{'_CLAIMS_._NOT_ASSOCIATED' | translate}}</label>
                        <input rgiRxInput type="checkbox" formControlName="association">
                    </rgi-rx-form-field>
                </div>
            </div>
        </div>
    </form>

    <span *ngIf="errorMessage" class="col-sm-12 content-error validate-error"
    attr.data-qa="elaboration-request-search-error">
    <span class="rgifont rgi-exclamation-triangle"></span>
    <span>{{errorMessage}}</span>
    </span>

    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button rgi-rx-button type="button" class="btn btn-warning pull-right" (click)="emptyFields()"
                attr.data-qa="dialogue-handler-search-empty-btn">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
        </div>
        <div class="btn-group">
            <button rgi-rx-button type="button" (click)="goToSearchResults()" class="btn btn-warning pull-right"
                attr.data-qa="dialogue-handler-search-find-btn" id="dialogue-handler-search-submit">{{ '_CLAIMS_._BUTTONS_._FIND' |
                translate }}</button>
        </div>
        <div class="btn-group" *ngIf="buttonCondition && userHasPermission">
            <button rgi-rx-button type="button" class="btn btn-warning pull-right"
                attr.data-qa="dialogue-handler-search-find-btn" (click)="openNewDialogue()">{{ '_CLAIMS_._BUTTONS_._NEW' | translate
                }}</button>
            <!-- <button rgi-rx-button type="button" class="btn btn-warning pull-right"
                attr.data-qa="dialogue-handler-search-find-btn" (click)="openNewDialogue()">{{ 'NEW' | translate
                }}</button> -->
        </div>
    </div>
</div>

<!-- [disabled]="!searchForm.isValidForm" -->

import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DscCirumstanceService } from './dsc-circumstances-service/dsc-circumstances-data.service';
import { ModalService } from '@rgi/rx/ui';
import { DscBaremsModalComponent } from './dsc-barems-modal/dsc-barems-modal.component';
import { Subject } from 'rxjs';
import { AlertService, EnumType, PolicyService } from '@rgi/digital-claims-common-angular';
import { GeneralClaimsData } from '../dsc-general-detail/dsc-general-detail.config';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';
import {
  CircumstanceIncident,
  CircumstanceMultiselect,
  INIT_COMBO_CIRCUMSTANCE,
  OutputClaimContext,
  OutputLiabilityDetermination,
  OutputLiabilityDeterminationV2
} from './dsc-circumstances-model/dsc-circumstances.config';
import { BaremEntity, CodiceDescrizione } from '../dsc-shared-model/dsc-model-utility';

@Component({
  selector: 'claims-dsc-circumstances-data',
  templateUrl: './dsc-circumstances-data.component.html',
  styleUrls: ['./dsc-circumstances-data.component.scss']
})
export class DscCircumstancesDataComponent implements OnInit {

  @Input() circumstanceIncident: CircumstanceIncident;
  @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();
  @Input() typeOpenComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;
  @Input() generalData: GeneralClaimsData;
  @Input() context: string;
  @Input() isNewClaim: boolean = false;

  @Output() outputFormcircumstanceIncident: EventEmitter<CircumstanceIncident> = new EventEmitter();

  dataForm: UntypedFormGroup; // Form in cui inserire i dati
  isAccordion: boolean = false; // Abilita / Disabilita la sezione in modo che sia collassabile
  enableNote: boolean = false; // Abilita il campo Note relativo alla forzatura della percentuale della responsabilità
  enableItems: boolean = false; // Abilita i campi relativi alla checkbox "Applica forzatura"
  isOneVehicle: boolean = false; // Oggetto utilizzato per verificare se si tratti di Uno o Più veicoli coinvolti
  dataCombo: CircumstanceMultiselect = INIT_COMBO_CIRCUMSTANCE; // Oggetto contenente i dati delle multiselect
  liabilityDetermination: OutputLiabilityDetermination = new OutputLiabilityDetermination();
  claimContext: OutputClaimContext = new OutputClaimContext();
  showCircumstanceForContext: boolean = true; // abilita la visualizzazione della sezione Accident Circumstance

  validazioneFormGlobale: boolean = true;

  localContext: string;
  italianContext: boolean = false;
  spagnaContext: boolean = false;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private alertService: AlertService,
    private dscCirumstanceService: DscCirumstanceService,
    private policyService: PolicyService,
    @Inject('authService') private authService,
  ) {
    this.localContext = this.context ? this.context : authService.getDefaultContext();
    this.italianContext = (this.localContext === null || this.localContext === 'IT');
    this.spagnaContext = (this.localContext === null || this.localContext === 'ES');
  }

  ngOnInit() {
    this.initComponentObject();
    this.resetFormSubject.subscribe(response => {
      if (response) {
        this.initComponentObject();
      }
    });
  }

  initComponentObject() {
    this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion;
    this.isOneVehicle = this.circumstanceIncident.vehiclesNumber < 2;

    //TODO: IIAB-8353 - IIAB-4479 - funziona per due veicoli , da gestire x tre o piu
    if (this.circumstanceIncident.percInsuredLiability !== null) {
      this.circumstanceIncident.percOtherPartyLiability = 100 - this.circumstanceIncident.percInsuredLiability;
    }

    //gestione per contesto
    if(this.localContext === "ES") {
      this.showCircumstanceForContext = this.generalData.ministerialBranchParty? this.generalData.ministerialBranchParty.filter((res: CodiceDescrizione) =>
        res.codice === 'ID' ||
        res.codice === 'IC').length > 0 : true;
    }

    this.dscCirumstanceService.populateCircumstanceMultiselectComponent().subscribe((response: CircumstanceMultiselect) => {
      this.dataCombo = response;

      // le combo estratte del be non devono essere mai condizionate, le vedo tutte a video sempre
      /* const tempCombo: EnumType[] = [];

      this.dataCombo.comboForcedLiability.forEach((element) => {
        if (element.codice !== '6' && element.codice !== '7') {
          tempCombo.push(element);
        }
        if (this.circumstanceIncident.signatureType === 1) {
          // Se UNA FIRMA escludi tutto tranne i codici 6 e 7
          if (element.codice === '6' || element.codice === '7') {
            tempCombo.push(element);
          }
        } else {
          if (element.codice !== '6' && element.codice !== '7') {
            tempCombo.push(element);
          }
        }
      });
      this.dataCombo.comboForcedLiability = tempCombo; */

      /*this.policyService.getPolicyContext(this.generalData.policyNumber).subscribe((responseContenxt: OutputClaimContext) => {
        this.claimContext = responseContenxt;

        for (const element of this.generalData.ministerialBranchParty){
          if (responseContenxt.code == "ES" && (element.codice == 'ID' || element.codice == 'IC')
              || responseContenxt.code == "IT" && element.codice == '000010' ) {

            this.enableAccordionCircumstanceData = true;
            this.enableFormDisplay = true;
            break;
          };
        }
      });*/

    });

    this.circumstanceIncident.forcingTechnicalResponsibilityNotes =
      (this.circumstanceIncident.forcingTechnicalResponsibilityNotes === ''
        ? null : this.circumstanceIncident.forcingTechnicalResponsibilityNotes);

    this.circumstanceIncident.aniaNotes = (this.circumstanceIncident.aniaNotes === '' ? null : this.circumstanceIncident.aniaNotes);

    this.dataForm = this.formBuilder.group({
      signatureType: [''], // Tipo firma
      percInsuredLiability: [null], // Percentuale responsabilità assicurato
      percOtherPartyLiability: [''], // Percentuale responsabilità controparte
      eventDenial: [''], // Negazione evento
      classificationType: [''], // Tipo di classificazione ANIA
      forcingTechnicalResponsibility: [''], // Applica forzatura percentuale responsabilità (civilistica)
      forcingTechnicalResponsibilityNotes: [null], // Note forzatura civilistica
      forcingAniaResponsibility: [''], // Applica forzatura responsabilità dichiarata ad Ania
      aniaNotes: [null], // Note ANIA
      reasonToForceLiability: [''], // Motivo forzatura
      forcedLiability: [''], // Responsabilità forzata
      liabilityType: [''], // Tipo di responsabilità
      liabilityBySystem: [''], // Ragione / Torto definita da sistema
      liabilityDeclared: [''], // Responsabilità dichiarata
      version: [''], //version
    });
    this.dataForm.patchValue(this.circumstanceIncident, {
      emitEvent: false
    });

    this.setValidatorsForm();

    this.enableNote = this.circumstanceIncident.forcingTechnicalResponsibility;
    this.enableItems = this.circumstanceIncident.forcingAniaResponsibility;

    this.enableItemForm();
  }

  /**
   * Aggiunge le validazioni nel form
   */
  setValidatorsForm() {

    // Se un veicolo
    if (this.isOneVehicle) {
     /* this.dataForm.clearAsyncValidators();
      this.dataForm.controls.percInsuredLiability.setValidators(
        Validators.required);
      this.dataForm.controls.signatureType.setValidators(
        Validators.required);
      this.dataForm.updateValueAndValidity();*/
      this.dataForm = this.formBuilder.group({
        signatureType: [this.circumstanceIncident.signatureType, Validators.required], // Tipo firma
        percInsuredLiability: [null, Validators.required], // Percentuale responsabilità assicurato
        percOtherPartyLiability: [null], // Percentuale responsabilità controparte
        eventDenial: [null], // Negazione evento
        classificationType: [null], // Tipo di classificazione ANIA
        forcingTechnicalResponsibility: [null], // Applica forzatura percentuale responsabilità (civilistica)
        forcingTechnicalResponsibilityNotes: [null], // Note forzatura civilistica
        forcingAniaResponsibility: [null], // Applica forzatura responsabilità dichiarata ad Ania
        aniaNotes: [null], // Note ANIA
        reasonToForceLiability: [null], // Motivo forzatura
        forcedLiability: [null], // Responsabilità forzata
        liabilityType: [null], // Tipo di responsabilità
        liabilityBySystem: [null], // Ragione / Torto definita da sistema
        liabilityDeclared: [null, this.isNewClaim ? Validators.nullValidator : Validators.required],
        version: ['', this.isNewClaim ? Validators.nullValidator : Validators.required] // Responsabilità dichiarata
      });
      this.dataForm.patchValue(this.circumstanceIncident, {
        emitEvent: false
      });

    } else {
    // Se più di un veicolo
    // Campi in comune sia in caso di un veicolo che più veicoli
    this.dataForm.controls.signatureType.setValidators(
      this.circumstanceIncident.signatureTypeRequired
        ? Validators.required
        : Validators.nullValidator);

    this.dataForm.controls.percInsuredLiability.setValidators(
      this.circumstanceIncident.percInsuredLiabilityRequired
        ? Validators.compose([Validators.required, Validators.max(100), Validators.min(0)])
        : Validators.nullValidator);

      this.dataForm.controls.percOtherPartyLiability.setValidators(
        this.circumstanceIncident.percOtherPartyLiabilityRequired
          ? Validators.compose([Validators.required, Validators.max(100), Validators.min(0)])
          : Validators.nullValidator);

      this.dataForm.controls.classificationType.setValidators(
        this.circumstanceIncident.classificationTypeRequired
          ? Validators.required
          : Validators.nullValidator);

      this.dataForm.controls.forcingTechnicalResponsibility.setValidators(
        this.circumstanceIncident.forcingTechnicalResponsibilityRequired || this.circumstanceIncident.forcingTechnicalResponsibility
          ? Validators.required
          : Validators.nullValidator);

      this.dataForm.controls.forcingTechnicalResponsibilityNotes.setValidators(
        this.circumstanceIncident.forcingTechnicalResponsibility
          ? Validators.required
          : Validators.nullValidator);

      this.dataForm.controls.forcingAniaResponsibility.setValidators(
        this.circumstanceIncident.forcingAniaResponsibilityRequired || this.circumstanceIncident.forcingAniaResponsibility
          ? Validators.required
          : Validators.nullValidator);

      this.dataForm.controls.aniaNotes.setValidators(
        this.circumstanceIncident.forcingAniaResponsibility
          ? Validators.required
          : Validators.nullValidator);

      this.dataForm.controls.reasonToForceLiability.setValidators(
        this.circumstanceIncident.forcingAniaResponsibility
          ? Validators.required
          : Validators.nullValidator);

      this.dataForm.controls.forcedLiability.setValidators(
        this.circumstanceIncident.forcingAniaResponsibility
          ? Validators.required
          : Validators.nullValidator);

      this.dataForm.controls.liabilityType.setValidators(
        this.circumstanceIncident.liabilityTypeRequired
          ? Validators.required
          : Validators.nullValidator);

      this.dataForm.controls.liabilityBySystem.setValidators(
        this.circumstanceIncident.liabilityBySystemRequired && this.italianContext
          ? Validators.required
          : Validators.nullValidator);

      this.dataForm.controls.liabilityDeclared.setValidators(
        this.circumstanceIncident.liabilityDeclaredRequired
          ? Validators.required
          : Validators.nullValidator);

      this.dataForm.controls.version.setValidators(
        this.circumstanceIncident.version
          ? Validators.required
          : Validators.nullValidator);
    }
    this.outputFormChange();
  }

  /**
   * Abilita / Disabilita i campi del form
   */
  enableItemForm() {
    // Campo "Tipo Firma" disabilitato deve essere sempre disabilitato
    this.dataForm.get('signatureType').disable();
   /*  if (this.generalData.claimType && this.generalData.claimType.includes('RCA')) {
      this.dataForm.get('signatureType').enable();
    } */

    // Classificazione Ania sempre abilitato
    this.dataForm.get('classificationType').enable();

    // Campo "Ragione / Torto definita da sistema" abilitato / disabilitato
    if (this.italianContext) {
      if (this.circumstanceIncident.liabilityBySystemEnabled) {
        this.dataForm.get('liabilityBySystem').enable();
      } else {
        this.dataForm.get('liabilityBySystem').disable();
      }
    }

    // Campo "Tipo di responsabilità" abilitato / disabilitato
    if (this.circumstanceIncident.liabilityTypeEnabled) {
      this.dataForm.get('liabilityType').enable();
    } else {
      this.dataForm.get('liabilityType').disable();
    }

    // Campo "Percentuale responsabilità assicurato" abilitato / disabilitato
    if (this.circumstanceIncident.percInsuredLiabilityEnabled) {
      this.dataForm.get('percInsuredLiability').enable();
    } else if(this.circumstanceIncident.vehiclesNumber <= 1) {
      this.dataForm.get('percInsuredLiability').enable();
    } else {
      this.dataForm.get('percInsuredLiability').disable();
    }

    // Campo "Percentuale responsabilità controparte" abilitato / disabilitato
    if (this.circumstanceIncident.percOtherPartyLiabilityEnabled) {
      this.dataForm.get('percOtherPartyLiability').enable();
    } else {
      this.dataForm.get('percOtherPartyLiability').disable();
    }

    // Campo "Responsabilità dichiarata" abilitato / disabilitato

    if(this.spagnaContext) {
      this.dataForm.get('liabilityDeclared').enable();
    } else {
      if (this.circumstanceIncident.liabilityDeclaredEnabled) {
        this.dataForm.get('liabilityDeclared').enable();
      } else {
          this.dataForm.get('liabilityDeclared').disable();
      }
    }

    // Campo "Applica forzatura responsabilità dichiarata ad Ania" abilitato / disabilitato
    if (this.circumstanceIncident.forcingAniaResponsibilityEnabled) {
      this.dataForm.get('forcingAniaResponsibility').enable();
    } else {
      this.dataForm.get('forcingAniaResponsibility').disable();
    }

    // Campo "Responsabilità forzata" abilitato / disabilitato (visibile se flag true forzatura ANIA)
    if (this.circumstanceIncident.comboForcedLiabilityEnabled && this.dataForm.get('forcingAniaResponsibility').value) {
      this.dataForm.get('forcedLiability').enable();
    } else {
      this.dataForm.get('forcedLiability').disable();
    }

    // Applica forzatura percentuale responsabilità (civilistica)
    if (this.circumstanceIncident.forcingTechnicalResponsibilityEnabled) {
      this.dataForm.get('forcingTechnicalResponsibility').enable();
    } else {
      this.dataForm.get('forcingTechnicalResponsibility').disable();
    }

  }

  calculatePercInsuredLiability() {
    if (!this.dataForm.controls.forcingTechnicalResponsibility.value && this.dataForm.controls.percOtherPartyLiability.value) {
      this.dataForm.get('percInsuredLiability').setValue(100 - this.dataForm.controls.percOtherPartyLiability.value);
    } else if (!this.dataForm.controls.forcingTechnicalResponsibility.value && !this.dataForm.controls.percOtherPartyLiability.value) {
      this.dataForm.get('percInsuredLiability').setValue('');
    }
  }

  calculatePercOtherPartyLiability() {
    if (!this.dataForm.controls.forcingTechnicalResponsibility.value && this.dataForm.controls.percInsuredLiability.value) {
      this.dataForm.get('percOtherPartyLiability').setValue(100 - this.dataForm.controls.percInsuredLiability.value);
    }
  }

  /**
   * Funzione che permette di visualizzare il campo note se viene flaggato
   * "Applica forzatura percentuale responsabilità"
   * @param event Prende in input l'evento scatenato dalla checkbox
   */
  enableForcingTechnicalResponsibility(event) {
    if (event.checked) {
      this.enableNote = true;
      this.dataForm.get('forcingTechnicalResponsibilityNotes').setValidators(Validators.required);
      this.dataForm.get('percInsuredLiability').enable();
      this.dataForm.get('reasonToForceLiability').enable();
      this.dataForm.get('forcedLiability').enable();
    } else {
      this.enableNote = false;
      this.dataForm.get('forcingTechnicalResponsibilityNotes').setValidators(Validators.nullValidator);
      this.dataForm.get('forcingTechnicalResponsibilityNotes').setValue('');

      this.dataForm.get('percInsuredLiability').disable();
      this.dataForm.get('reasonToForceLiability').disable();
      this.dataForm.get('forcedLiability').disable();

      if (this.dataForm.controls.percOtherPartyLiability.value !== ''
        && this.dataForm.controls.percOtherPartyLiability.value !== undefined) {
        this.dataForm.get('percInsuredLiability').setValue(100 - this.dataForm.controls.percOtherPartyLiability.value);
      } else {
        this.dataForm.get('percInsuredLiability').setValue('');
      }
    }
    this.outputFormChange();
  }

  /**
   * Funzione che permette di visualizzare i campi Motivo forzatura / Responsabilità forzata / Note
   * se viene flaggato "Applica forzatura responsabilità dichiarata ad Ania"
   * @param event Prende in input l'evento scatenato dalla checkbox
   */
  enableForcedLiability(event) {
    if (event.checked) {
      // Abilita la visualizzazione dei campi e li rende obbligatori
      this.enableItems = true;
      this.dataForm.get('reasonToForceLiability').setValidators(Validators.required);
      this.dataForm.get('forcedLiability').setValidators(Validators.required);
      this.dataForm.get('forcedLiability').enable();
      this.dataForm.get('aniaNotes').setValidators(Validators.required);
      this.dataForm.setErrors({ required: true });
    } else {
      // Disabilita la visualizzazione dei campi, elimina i validatori e li valorizza vuoti
      this.enableItems = false;
      this.dataForm.get('reasonToForceLiability').setValidators(Validators.nullValidator);
      this.dataForm.get('forcedLiability').setValidators(Validators.nullValidator);
      this.dataForm.get('aniaNotes').setValidators(Validators.nullValidator);

      this.dataForm.get('reasonToForceLiability').setValue(this.circumstanceIncident.reasonToForceLiability);
      this.dataForm.get('forcedLiability').setValue(this.circumstanceIncident.forcedLiability);
      this.dataForm.get('aniaNotes').setValue(this.circumstanceIncident.aniaNotes);
      this.dataForm.get('forcedLiability').disable();
    }
    this.outputFormChange();
  }

  /**
   * Funzione per l'apertura della modale barems
   */
  openModalBarems() {
    const baremA = this.circumstanceIncident.baremEntityA
      ? this.circumstanceIncident.baremEntityA.objectId
      : '';

    const baremB = this.circumstanceIncident.baremEntityB
      ? this.circumstanceIncident.baremEntityB.objectId
      : '';

    this.modalService.open(DscBaremsModalComponent, {
      baremEntityA: baremA,
      baremEntityB: baremB,
      baremARequired: this.circumstanceIncident.baremEntityARequired,
      baremBRequired: this.circumstanceIncident.baremEntityBRequired,
    }).onClose.subscribe(response => {
      if (response && response !== 'Close') {
        const resBaremA: BaremEntity = response.baremEntityA;
        const resBaremB: BaremEntity = response.baremEntityB;

        this.circumstanceIncident.baremEntityA = resBaremA;
        this.circumstanceIncident.baremEntityB = resBaremB;

        if (resBaremA.barem.codice && resBaremB.barem.codice) {
          this.dscCirumstanceService
            .calculateLiability(resBaremA.barem.codice, resBaremB.barem.codice, this.generalData.policyNumber)
            .subscribe((res: OutputLiabilityDeterminationV2) => {
              if(res.liability === '1'){
                this.circumstanceIncident.liabilityType = '1';
                this.circumstanceIncident.percInsuredLiability = 100;
                this.circumstanceIncident.percOtherPartyLiability = 0;
                this.dataForm.get('percInsuredLiability').setValue(100);
                this.dataForm.get('percOtherPartyLiability').setValue(0);
                this.dataForm.get('liabilityType').setValue(1);
                this.dataForm.get('liabilityDeclared').setValue(1);
                this.dataForm.get('version').setValue(res.version);
              } else if (res.liability === '2'){
                this.circumstanceIncident.liabilityType = '2';
                this.circumstanceIncident.percInsuredLiability = 0;
                this.circumstanceIncident.percOtherPartyLiability = 100;
                this.dataForm.get('percInsuredLiability').setValue(0);
                this.dataForm.get('percOtherPartyLiability').setValue(100);
                this.dataForm.get('liabilityType').setValue(2);
                this.dataForm.get('liabilityDeclared').setValue(2);
                this.dataForm.get('version').setValue(res.version);
              } else if (res.liability === '4'){
                this.circumstanceIncident.liabilityType = '4';
                this.circumstanceIncident.percInsuredLiability = 0;
                this.circumstanceIncident.percOtherPartyLiability = 0;
                this.dataForm.get('percInsuredLiability').setValue(0);
                this.dataForm.get('percOtherPartyLiability').setValue(0);
                this.dataForm.get('liabilityType').setValue(null);
                this.dataForm.get('liabilityDeclared').setValue(null);
                this.dataForm.get('version').setValue(res.version);
              }

              /*this.liabilityDetermination = res;

              this.circumstanceIncident.percInsuredLiability =
                parseInt(this.liabilityDetermination.result.liability.liabilityPercentage, 10);
              this.dataForm.get('percInsuredLiability').setValue(this.circumstanceIncident.percInsuredLiability);

              if (this.dataForm.get('percInsuredLiability').value) {
                this.circumstanceIncident.percOtherPartyLiability = (100 - this.dataForm.get('percInsuredLiability').value);
              }
              this.dataForm.get('percOtherPartyLiability').setValue(this.circumstanceIncident.percOtherPartyLiability);

              this.circumstanceIncident.liabilityBySystem = this.liabilityDetermination.result.liability.determinatedLiability.codice;
              if (this.italianContext) {
                this.dataForm.get('liabilityBySystem').setValue(this.circumstanceIncident.liabilityBySystem);
              }

              this.circumstanceIncident.liabilityType = this.liabilityDetermination.result.liability.liabilityType.codice;
              this.dataForm.get('liabilityType').setValue(this.circumstanceIncident.liabilityType);*/

              this.outputFormChange();
            }, err => {
              // console.log('Circostanze - Barems: Errore durante il caricamento dei dati');
              this.alertService.translatedMessage('Error loading data');
            });
        } else {
          // Se i baremes sono stati svuotati ed è stata svuotata la data denuncia assicurato
          if (this.circumstanceIncident.isOccurrenceDateChanged) {

            // Percentuale assicurato controparte
            this.dataForm.get('percOtherPartyLiability').enable();
            this.circumstanceIncident.percOtherPartyLiabilityEnabled = true;
            this.circumstanceIncident.percOtherPartyLiabilityRequired = true;
            this.dataForm.controls.percOtherPartyLiability.setValidators(
              Validators.compose([Validators.required, Validators.max(100), Validators.min(0)]));

            // Responsabilità dichiarata
            this.dataForm.get('liabilityDeclared').disable();
            this.circumstanceIncident.liabilityDeclaredEnabled = false;

            // Forzatura civilistica
            this.dataForm.get('forcingTechnicalResponsibility').enable();
            this.circumstanceIncident.forcingTechnicalResponsibilityEnabled = true;

            // Forzatura ANIA
            this.dataForm.get('forcingAniaResponsibility').disable();
            this.dataForm.get('forcingAniaResponsibility').setValue(false);
            this.circumstanceIncident.forcingAniaResponsibility = false;
            this.circumstanceIncident.forcingAniaResponsibilityEnabled = false;
            this.circumstanceIncident.forcingAniaResponsibilityRequired = false;
            this.dataForm.controls.forcingAniaResponsibility.setValidators(Validators.nullValidator);

            if (!this.circumstanceIncident.forcingTechnicalResponsibility) {
              // Percentuale nostro assicurato
              this.dataForm.get('percInsuredLiability').disable();
              this.dataForm.get('percInsuredLiability').setValue('');
              this.circumstanceIncident.percInsuredLiability = undefined;
              this.circumstanceIncident.percInsuredLiabilityEnabled = false;
              this.circumstanceIncident.percInsuredLiabilityRequired = true;
              this.dataForm.controls.percInsuredLiability.setValidators(
                Validators.compose([Validators.required, Validators.max(100), Validators.min(0)]));

              // Responsabilità dichiarata
              this.dataForm.get('liabilityDeclared').setValue('');
              this.circumstanceIncident.liabilityDeclared = '';
              this.circumstanceIncident.liabilityDeclaredRequired = false;
              this.dataForm.controls.liabilityDeclared.setValidators(Validators.nullValidator);

              // Forzatura civilistica
              this.circumstanceIncident.forcingTechnicalResponsibilityRequired = false;
              this.dataForm.controls.forcingTechnicalResponsibility.setValidators(Validators.nullValidator);

            } else {

              // Percentuale nostro assicurato
              this.dataForm.get('percInsuredLiability').enable();
              this.circumstanceIncident.percInsuredLiabilityEnabled = true;
              this.circumstanceIncident.percInsuredLiabilityRequired = true;
              this.dataForm.controls.percInsuredLiability.setValidators(
                Validators.compose([Validators.required, Validators.max(100), Validators.min(0)]));

              // Responsabilità dichiarata
              this.circumstanceIncident.liabilityDeclaredRequired = true;
              this.dataForm.controls.liabilityDeclared.setValidators(Validators.required);

              // Note obbligatorie se forzatura civilistica selezionata
              this.dataForm.controls.forcingTechnicalResponsibilityNotes.setValidators(Validators.required);
            }
          } else if (this.circumstanceIncident.isOccurrenceDateFromEmptyToValued) {
            // Se i baremes sono stati svuotati ed è stata valorizzata la data denuncia assicurato

            this.circumstanceIncident.baremEntityARequired = true;
            this.circumstanceIncident.baremEntityBRequired = true;

            // Percentuale assicurato controparte
            this.dataForm.get('percOtherPartyLiability').enable();
            this.circumstanceIncident.percOtherPartyLiabilityEnabled = true;
            this.circumstanceIncident.percOtherPartyLiabilityRequired = true;
            this.dataForm.controls.percOtherPartyLiability.setValidators(
              Validators.compose([Validators.required, Validators.max(100), Validators.min(0)]));

            // Percentuale nostro assicurato
            this.dataForm.get('percInsuredLiability').disable();
            this.circumstanceIncident.percInsuredLiabilityEnabled = false;
            this.circumstanceIncident.percInsuredLiabilityRequired = true;
            this.dataForm.controls.percInsuredLiability.setValidators(
              Validators.compose([Validators.required, Validators.max(100), Validators.min(0)]));


            // Responsabilità dichiarata
            this.dataForm.get('liabilityDeclared').setValue('');
            this.dataForm.get('liabilityDeclared').disable();
            this.circumstanceIncident.liabilityDeclared = '';
            this.circumstanceIncident.liabilityDeclaredRequired = false;
            this.circumstanceIncident.liabilityDeclaredEnabled = false;
            this.dataForm.controls.liabilityDeclared.setValidators(Validators.nullValidator);

            // Forzatura civilistica
            this.circumstanceIncident.forcingTechnicalResponsibilityEnabled = true;
            this.dataForm.get('forcingTechnicalResponsibility').enable();
            this.circumstanceIncident.forcingTechnicalResponsibilityRequired = false;
            this.dataForm.controls.forcingTechnicalResponsibility.setValidators(Validators.nullValidator);

            // Forzatura ANIA
            this.dataForm.get('forcingAniaResponsibility').disable();
            this.dataForm.get('forcingAniaResponsibility').setValue(false);
            this.circumstanceIncident.forcingAniaResponsibility = false;
            this.circumstanceIncident.forcingAniaResponsibilityEnabled = false;
            this.circumstanceIncident.forcingAniaResponsibilityRequired = false;
            this.dataForm.controls.forcingAniaResponsibility.setValidators(Validators.nullValidator);
          }
          this.outputFormChange();
        }
      }
    });
  }

  /**
   * Funzione utilizzata per il passaggio degli oggetti al chiamante
   */
  outputFormChange() {
    this.validazioneFormGlobale = true;

    if (!this.isOneVehicle) {
      this.validationMandatoryFieldsValued();
    }

    //if (this.dataForm.valid) {
      const circumstanceIncidentUpdate: CircumstanceIncident = {
        ...this.circumstanceIncident
      };
      circumstanceIncidentUpdate.aniaNotes = this.dataForm.controls.aniaNotes.value;
      circumstanceIncidentUpdate.classificationType = this.dataForm.controls.classificationType.value;
      circumstanceIncidentUpdate.eventDenial = this.dataForm.controls.eventDenial.value;
      circumstanceIncidentUpdate.forcedLiability = this.dataForm.controls.forcedLiability.value;
      circumstanceIncidentUpdate.forcingAniaResponsibility = this.dataForm.controls.forcingAniaResponsibility.value;
      circumstanceIncidentUpdate.forcingTechnicalResponsibility = this.dataForm.controls.forcingTechnicalResponsibility.value;
      circumstanceIncidentUpdate.liabilityBySystem = this.italianContext ? this.dataForm.controls.liabilityBySystem.value : null;
      circumstanceIncidentUpdate.liabilityDeclared = this.dataForm.controls.liabilityDeclared.value;
      circumstanceIncidentUpdate.liabilityType = this.dataForm.controls.liabilityType.value;
      circumstanceIncidentUpdate.forcingTechnicalResponsibilityNotes = this.dataForm.controls.forcingTechnicalResponsibilityNotes.value;
      circumstanceIncidentUpdate.percInsuredLiability = this.dataForm.controls.percInsuredLiability.value;
      circumstanceIncidentUpdate.percOtherPartyLiability = this.dataForm.controls.percOtherPartyLiability.value;
      circumstanceIncidentUpdate.reasonToForceLiability = this.dataForm.controls.reasonToForceLiability.value;
      circumstanceIncidentUpdate.signatureType = this.dataForm.controls.signatureType.value;
      circumstanceIncidentUpdate.version = this.dataForm.controls.version?.value;
      circumstanceIncidentUpdate.isValidForm = this.dataForm.valid;
      this.outputFormcircumstanceIncident.emit(circumstanceIncidentUpdate);
    //}
  }

  validationMandatoryFieldsValued() {
    // Se Barem A (nostro veicolo) è obbligatorio e non è valorizzato, invalido il form
    this.setFormValidation(!this.circumstanceIncident.baremEntityARequired
      || (this.circumstanceIncident.baremEntityA && this.circumstanceIncident.baremEntityA.objectId !== ''));

    // Se Barem B (veicolo controparte) è obbligatorio e non è valorizzato, invalido il form
    this.setFormValidation(!this.circumstanceIncident.baremEntityBRequired
      || (this.circumstanceIncident.baremEntityB && this.circumstanceIncident.baremEntityB.objectId !== ''));

    this.fieldValidationForcingTechnicalResponsibility();

    // Validazione dei campi relativi al flag "Applica forzatura responsabilità dichiarata ad Ania"
    this.fieldValidationForcingAniaResponsibility();

    this.dataForm.setErrors(this.validazioneFormGlobale ? null : { required: true });
  }

  /**
   * Validazione dei campi presenti se il flag "Applica forzatura percentuale responsabilità" è attivo
   */
  fieldValidationForcingTechnicalResponsibility() {
    if ((this.dataForm.controls.forcingTechnicalResponsibility.value
      && this.dataForm.controls.forcingTechnicalResponsibilityNotes.value !== '')
      || !this.dataForm.controls.forcingTechnicalResponsibility.value) {

      // Se "Applica forzatura percentuale responsabilità" è selezionato ed il campo note è valorizzato
      this.setFormValidation(true);

    } else if (this.dataForm.controls.forcingTechnicalResponsibility.value
      && this.dataForm.controls.forcingTechnicalResponsibilityNotes.value === '') {

      // Se "Applica forzatura percentuale responsabilità" è selezionato ed il campo note non è valorizzato
      this.setFormValidation(false);
    }
  }

  /**
   * Validazione dei campi presenti se il flag "Applica forzatura responsabilità dichiarata ad Ania" è attivo
   */
  fieldValidationForcingAniaResponsibility() {
    if ((this.dataForm.controls.forcingAniaResponsibility.value
      && this.dataForm.controls.reasonToForceLiability.value !== null
      && this.dataForm.controls.reasonToForceLiability.value !== ''
      && this.dataForm.controls.forcedLiability.value !== null
      && this.dataForm.controls.forcedLiability.value !== ''
      && this.dataForm.controls.aniaNotes.value !== '')
      || !this.dataForm.controls.forcingAniaResponsibility.value) {

      // Se "Applica forzatura" è selezionato ed i campi attivati sono tutti valorizzati
      this.setFormValidation(true);

    } else if (this.dataForm.controls.forcingAniaResponsibility.value
      && (this.dataForm.controls.reasonToForceLiability.value === null
        || this.dataForm.controls.reasonToForceLiability.value === ''
        || this.dataForm.controls.forcedLiability.value === null
        || this.dataForm.controls.forcedLiability.value === ''
        || this.dataForm.controls.aniaNotes.value === '')) {

      // Se "Applica forzatura" è selezionato ed i campi attivati non sono tutti valorizzati
      this.setFormValidation(false);
    }
  }

  /**
   * Funzione che gestisce la validazione del form
   */
  setFormValidation(validField: boolean) {
    if (this.validazioneFormGlobale) {
      this.validazioneFormGlobale = this.validazioneFormGlobale && validField;
    }
  }

}
